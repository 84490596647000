//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { mapState } from 'vuex'
    import ImageSrcsetMixin from '~/mixins/ImageSrcsetMixin';
    import userChecks from '~/mixins/userChecks';
    import { formatDate, convertDuration } from '@/helpers/filters.js'
    import videoplayer from "~/components/content/video/videoplayer";
    import dummy from '~/components/content/dummy/detailed_dummy.vue'
    export default {
        mixins: [ImageSrcsetMixin, userChecks],
        async fetch() {
            await this.$store.dispatch('resourcesStore/getResources', this.queries.videoOfTheWeek)
        },
        computed: {
            ...mapState({
                queries: ({ resourcesStore: {queries} }) => queries,
                videoOfTheWeek: ({ resourcesStore: {videoOfTheWeek} }) => videoOfTheWeek,
                loading: ({ resourcesStore: {loading} }) => loading,
                playerOptions: ({ mxmStore: {playerOptions} }) => playerOptions,
                stream: ({ mxmStore: {stream} }) => stream,
                killStream: ({ mxmStore: {killStream} }) => killStream,
                playerState: ({ mxmStore: {playerState} }) => playerState,
            })
        },
        components: {
            videoplayer,
            dummy
        },
        data() { 
            return {
                playvid: false,
                hover: false,
                line_complete: false,
                trailer: false,
            }
        },
        methods: {
            formatDate,
            convertDuration,
            taphandler() {
                this.$router.push('/seksfilms/' + this.videoOfTheWeek.data[0].slug);
            },
            endHandler(){
              if (!this.line_complete) {
                this.cancelAllPreviews();
            }
        },
        hoverHandler(todo) {
          if (this.videoOfTheWeek.data[0].clips && this.videoOfTheWeek.data[0].clips.data[0]) {
            this.hover = todo; 
            var self = this;
            setTimeout(function() {
                self.playvid = true;
            }, 800);
        }
    },
    touchHandler() {
      var self = this;
      this.cancelAllPreviews();
      if (this.videoOfTheWeek.data[0].clips && this.videoOfTheWeek.data[0].clips.data[0]) {
        this.startPreview();
    }
},
startPreview(){
    var self = this;
    this.hover = true;
    setTimeout(function() {
        var element = document.getElementById('video_' + self.videoOfTheWeek.data[0].id);
        if (element) {
          element.play();
          element.classList.add('active');
          self.line_complete = true;
      }
  }, 500);
},
cancelAllPreviews(){
  var self = this;

  document.querySelectorAll('.preview').forEach(function(element) { 
    if (element && element.id != 'video_' + self.videoOfTheWeek.data[0].id) {
      element.autoplay = false;
      element.load();
      element.classList.remove('active');
  }
});
  document.querySelectorAll('.line_animation').forEach(function(element) {
    if (element && element.id != 'line_' + self.videoOfTheWeek.data[0].id) {
      element.classList.remove('active');
  }
});
  this.playvid = false;
  this.hover = false
  this.line_complete = false;
},
loadTrailer(videoId) {
    var dataObj = [];
    dataObj.video_id = videoId;
    dataObj.resource_id = this.videoOfTheWeek.data[0].id;
    dataObj.frontend_id = 5;
    dataObj.type = 'trailer';
    dataObj.location = 'votw';
    this.$store.dispatch('mxmStore/getFreeStream', dataObj)
}
},
watch: {
  stream: function() {
    if (this.stream.location != 'votw') {
        this.trailer = false;
    }
    if (this.stream.location == 'votw' && this.stream.sources && this.stream.sources.streams) {
        this.trailer = true;
        if (this.videoOfTheWeek.data[0].images.data.thumb) {
            var thumb = this.$config.cdn + this.videoOfTheWeek.data[0].images.data.thumb[0].path
        } else {
            var thumb = this.$config.cdn + this.$config.errorimg;
        }
        let playerOptions = {
            autoplay: true,
            poster: thumb,
            sources: [{
                src: this.stream.sources.streams.mpd.url,
                type: this.stream.sources.streams.mpd.mimetype
            }]
        }
        this.$store.commit('mxmStore/updatePlayerOptions', playerOptions);
        this.$store.commit('mxmStore/setPlayerType', this.stream.type);
    }
}
}
}
