var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"videoplayer",class:[
      _vm.playerState.status,
      _vm.playerState.fullscreen ? 'videoplayer--fullscreen' : '' ],attrs:{"id":"playerwrapper"}},[_c('video',{ref:"videoPlayer",staticClass:"video-js vjs-default-skin vjs-fluid",attrs:{"id":"SecretCirclePlayer","controls":"","preload":"true","playsinline":""},on:{"click":function($event){return _vm.goTo()}}}),_vm._v(" "),(_vm.playerState && _vm.playerState.type && _vm.playerState.type == 'preroll')?_c('aside',{staticClass:"preroll_link",attrs:{"id":"preroll"},on:{"click":function($event){return _vm.goTo()}}}):_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.playerState.type != 'trailer' &&
      _vm.playerState.type != 'showreel' &&
      !_vm.playerState.fullscreen &&
      !_vm.$root.isMobile
      ),expression:"\n      playerState.type != 'trailer' &&\n      playerState.type != 'showreel' &&\n      !playerState.fullscreen &&\n      !$root.isMobile\n      "}],staticClass:"vjs-back vjs-control vjs-button",attrs:{"id":"vjs-back-js"},on:{"click":function($event){return _vm.disableFS()}}},[_c('span',{staticClass:"vjs-icon-placeholder"},[_c('fa',{staticClass:"icon left_side",attrs:{"icon":['fas', 'arrow-left']}})],1)]),_vm._v(" "),_c('div',{staticClass:"vjs-video-controls",attrs:{"id":"vjs-controls-js"}},[_c('button',{staticClass:"vjs-rewind-control vjs-rewind-backward",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.handlePlayback('backward')}}},[_c('span',{staticClass:"vjs-icon-placeholder",class:_vm.animateBackward ? 'animate' : ''},[_c('backward'),_c('i',[_vm._v("15")])],1)]),_vm._v(" "),_c('button',{staticClass:"vjs-icon-placeholder vjs-playbutton",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.togglePlayState()}}},[_c('fa',{directives:[{name:"show",rawName:"v-show",value:(!_vm.playing),expression:"!playing"}],staticClass:"icon play",attrs:{"icon":['fas', 'play']}}),_vm._v(" "),_c('fa',{directives:[{name:"show",rawName:"v-show",value:(_vm.playing),expression:"playing"}],staticClass:"icon play",attrs:{"icon":['fas', 'pause']}})],1),_vm._v(" "),_c('button',{staticClass:"vjs-rewind-control vjs-rewind-forward",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.handlePlayback('forward')}}},[_c('span',{staticClass:"vjs-icon-placeholder",class:_vm.animateForward ? 'animate' : ''},[_c('forward'),_c('i',[_vm._v("15")])],1)])]),_vm._v(" "),(_vm.videoTitle)?_c('h1',{staticClass:"vjs-title",attrs:{"id":"vjs-title-js"}},[_vm._v("\n  "+_vm._s(_vm.videoTitle)+"\n")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }