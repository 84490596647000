//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import videojs from "video.js";
// import "video.js/dist/video-js.css";
import { mapState, mapGetters, mapActions } from 'vuex' 
export default {
    name: "videoplayer",
    data() { 
        return {
            animateBackward: false,
            animateForward: false,
            player: null,
            playing: false,
            stopUpdating: false,
        }
    },
    components: {
        forward: () => import('@/assets/svg/player_forward_2_los_klein.svg'),
        backward: () => import('@/assets/svg/player_backward_2_los_klein.svg'),
    },
    computed: {
        ...mapState({
            video: ({ resourcesStore: {video} }) => video,
            playerState: ({ mxmStore: {playerState} }) => playerState,
            playerOptions: ({ mxmStore: {playerOptions} }) => playerOptions,
            stream: ({ mxmStore: {stream} }) => stream,
        }),
        videoTitle() {
            if (this.video.title && this.playerState.type !== 'preroll' && this.playerState.type !== 'trailer') {
                return this.video.title;
            } else if (this.playerState.type === 'preroll') {
                return 'Reclame';
            }
            return '';
        }
    },
    methods: {
        ...mapActions({
            updateStream: 'mxmStore/updateStream',
            updateFreeStream: 'mxmStore/updateFreeStream',
            authSocket: 'mxmStore/authSocket',
        }),
        intrvl(todo) {
            var self = this;
            this.interval = setInterval(function() {
                self.update('update');  
            }, 10000);
            if (todo != 'set') {
                clearInterval(this.interval);
            }
        },
        playerEventEnded(){
            if (this.playerState.type == 'preroll') {
                this.$store.commit('mxmStore/clearStream');
                var dataObj = [];
                dataObj.video_id = this.video.video_free.id;
                dataObj.resource_id = this.video.id;
                dataObj.frontend_id = 1;
                dataObj.type = 'free_film';
                dataObj.location = 'seksfilm';
                this.$store.dispatch('mxmStore/getFreeStream', dataObj)
            } else {
                this.playing = false;
                this.$store.commit('mxmStore/setPlayerStatus', 'ended');
                this.player.hasStarted(false);
                this.player.posterImage.show(); 
                this.update('stop');
                this.stopUpdating = true;
                this.$emit('videoEnded');
                this.intrvl('clear');
            }
        },
        playerEventPaused(){
            this.playing = false;
            if (this.playerState.status != 'exit') {
                this.$store.commit('mxmStore/setPlayerStatus', 'pause');
            }
            this.update('pause');
            this.intrvl('clear');
            this.$emit('videoPaused');
        },
        playerSetupEvents(){
            this.player.on('ended', function(){ var a = window.playerEvents.playerEventEnded(); });
        },
        playerEventPlaying(){
            this.playStream();
        },
        playStream() {
            this.playing = true;
            this.$store.commit('mxmStore/setPlayerStatus', 'playing');
            var dataobj = [];
            dataobj.action = 'play';
            if (this.playerState.type == 'film' && !this.stopUpdating || this.playerState.type == 'subscription' && !this.stopUpdating || this.playerState.type == 'episode' && !this.stopUpdating) {  
                dataobj.stream_id = this.stream.id
                this.updateStream(dataobj);     
                this.intrvl('set');
            } 
            if (this.playerState.type != 'film' && this.playerState.type != 'preroll' && this.playerState.type != 'subscription' && !this.stopUpdating) {  
                dataobj.stream_id = this.stream.id
                this.updateFreeStream(dataobj);     
            }
            this.player.play();
            this.$emit('videoPlaying');
        },
        playerMounted(){
            this.$emit('playerMounted');
        },
        disableFS(check){
            if (!check) {
                this.player.pause();
            }                
            this.$store.commit('mxmStore/setPlayerStatus', 'exit');
        },
        update(action) {
            if (action == 'update' && this.playerState.status != 'playing' ) {
                return;
            }
            var dataobj = [];
            dataobj.action = action;
            if (this.playerState.type == 'film' && !this.stopUpdating || this.playerState.type == 'episode' && !this.stopUpdating) {
                dataobj.stream_id = this.stream.id
                this.updateStream(dataobj);
            } 
            if (this.playerState.type != 'film' &&  !this.stopUpdating) {
                dataobj.stream_id = this.stream.id
                this.updateFreeStream(dataobj);
            }
        },
        handlePlayback(direction) {
            if (direction === 'backward') {
                this.animateBackward = true;
                this.updatePlayerTime(-15);
            } else if (direction === 'forward') {
                this.animateForward = true;
                this.updatePlayerTime(15);
            }

            setTimeout(() => {
                this.animateBackward = false;
                this.animateForward = false;
            }, 1000);
        },
        updatePlayerTime(offset) {
            var newTime = this.player.currentTime() + offset;
            this.player.currentTime(newTime);
        },
        togglePlayState() {
            if (this.player.paused()) {
                this.player.play();
                return;
            }
            this.player.pause();
        },
        skipTo() {
            this.player.currentTime(2000);
        },
        goTo() {
            this.player.play();
            if (this.stream.preroll_info) {
                console.log(this.stream.preroll_info.target);
                if (this.stream.preroll_info.target === '=' || this.stream.preroll_info.target === '_self') {
                    window.open(this.stream.preroll_info.href, '_self');
                } else {
                    window.open(this.stream.preroll_info.href, '_blank');
                }
            }
        },
        moveCustomElementsInsidePlayer() {
            const volume = document.getElementsByClassName('vjs-volume-panel');
            const back = document.getElementById('vjs-back-js');
            const title = document.getElementById('vjs-title-js');
            const controls = document.getElementById('vjs-controls-js');

            back && volume[0].after(back);
            title && volume[0].after(title);
            controls && volume[0].after(controls);
        }
    },
    mounted() {
        window.playerEvents = this;
        this.player = videojs(this.$refs.videoPlayer, this.playerOptions);

        var el = document.getElementById('SecretCirclePlayer');
        var pre = document.getElementById('preroll');

        var picinpic = document.getElementsByClassName('vjs-picture-in-picture-control');
        var seektolive = document.getElementsByClassName('vjs-seek-to-live-text');
        if (seektolive) {
            seektolive[0].textContent = "ADVERTENTIE";
        }

        if (el && pre) {
            el.appendChild(pre);
        }

        this.moveCustomElementsInsidePlayer();

        var playing = this.playerEventPlaying;
        var pause = this.playerEventPaused;
        var ended = this.playerEventEnded;

        var player=videojs('SecretCirclePlayer');

        player.on("playing", function(){
            playing();
        });
        player.on("pause", function(){
            pause();
        });
        player.on("ended", function(){
            ended();
        });

    // Disable seeking on preroll
        if (this.playerState && this.playerState.type && this.playerState.type == 'preroll') {
            var currentTime = 0;
            player.on("seeking", function(event) {
              if (currentTime < player.currentTime()) {
                player.currentTime(currentTime);
            }
        });
            player.on("seeked", function(event) {
              if (currentTime < player.currentTime()) {
                player.currentTime(currentTime);
            }
        });
        }

        var self = this;
        var fullscreen = '';
        player.on("fullscreenchange", function(){
            fullscreen = !fullscreen;
            self.$store.commit('mxmStore/setPlayerFullscreen', fullscreen);
        });

    },
    beforeDestroy() {
        this.$store.commit('mxmStore/clearStream');
    // TODO: implement deletion of event listeners, to prevent 
    // error "Cannot read property vdata<numbers> of null" (this does not seem critical)
        if (this.player) { 
            this.player.dispose();
        }
    }
}
