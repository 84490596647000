export default {
  computed: {
    generateSrcset() {
      return function(imageData) {
        // Assuming imageData.resolutions is an object with keys like '126x70', '284x160', etc.
        const base = this.$config.cdn;
        let srcsetString = Object.entries(imageData.resolutions).map(([key, resolution]) => {
          const fileName = imageData.path.replace('.jpg', `-${key}.jpg`);
          const optimizedImageSrc = this.$img(`${base}${fileName}`, { ...this.$img.options.presets.webp_85.modifiers });
          return `${optimizedImageSrc} ${resolution.x}w`;
        }).join(', ');

        return srcsetString;
      };
    }
  }
}
